export const fetchReview = async (documentation) => {
    try {
        const response = await fetch('https://osler.replit.app/safer_dx_assessment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ documentation })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`); // This will be caught in the catch block below
        }

        const data = await response.json();
        return data.assessment; // Extracting the DDX from the response
    } catch (error) {
        console.error('Error fetching DDX:', error); // Log error here as well
        throw error; // Rethrow to handle it in the generateDdx try-catch block
    }
}

// Example function to simulate fetching DDX from an API endpoint.
export const fetchDdx = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/ddx_nostreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`); // This will be caught in the catch block below
        }

        const data = await response.json();
        return data.ddx; // Extracting the DDX from the response
    } catch (error) {
        console.error('Error fetching DDX:', error); // Log error here as well
        throw error; // Rethrow to handle it in the generateDdx try-catch block
    }
};

export const fetchTidyNotes = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/tidy_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.notes;
    } catch (error) {
        console.error('Error fetching tidy notes:', error);
        throw error;
    }
};

export const fetchIllnessScript = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/get_illness_script_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.illness_script;
    } catch (error) {
        console.error('Error fetching illness_script:', error);
        throw error;
    }
};

export const fetchInvestigationSuggestion = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/suggest_investigations_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.suggested_investigations;
    } catch (error) {
        console.error('Error fetching suggested_investigations:', error);
        throw error;
    }
};

export const fetchPESuggestion = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/suggest_pe_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.suggested_pe;
    } catch (error) {
        console.error('Error fetching suggested_physical_exam:', error);
        throw error;
    }
};


export const fetchHistorySuggestions = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/suggest_history_questions_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.suggested_history_questions;
    } catch (error) {
        console.error('Error fetching suggested_history:', error);
        throw error;
    }
};


export const fetchRedFlags = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/get_red_flags_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.red_flags;
    } catch (error) {
        console.error('Error fetching red_flags:', error);
        throw error;
    }
};


//add fetch RxSuggestions, TxSuggestions, ReferralSuggestions, FUSuggestions
export const fetchRxSuggestions = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/suggest_rx_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.suggested_rx;
    } catch (error) {
        console.error('Error fetching suggested_rx:', error);
        throw error;
    }
};

export const fetchTxSuggestions = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/suggest_tx_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.suggested_tx;
    } catch (error) {
        console.error('Error fetching suggested_tx:', error);
        throw error;
    }
};

export const fetchFUSuggestions = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/suggest_fu_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.suggested_fu;
    } catch (error) {
        console.error('Error fetching suggested_fu:', error);
        throw error;
    }
};

export const fetchReferralSuggestions = async (text) => {
    try {
        const response = await fetch('https://osler.replit.app/suggest_referral_nonstreaming', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data.suggested_referral;
    } catch (error) {
        console.error('Error fetching suggested_referral:', error);
        throw error;
    }
};

