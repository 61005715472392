import React from 'react';
import './ConsentModal.css'; // Make sure to create a ConsentModal.css file for styling

const ConsentModal = ({ onConsent }) => {
    return (
        <div className="consent-modal-overlay">
            <div className="consent-modal">
                <div className="consent-modal-header">
                    <h2>Osler Clinical Co-Pilot (Prototype) - v0.0.1</h2>
                </div>
                <div className="consent-modal-body">
                <strong>What is Osler?</strong>
                    <p>
                     a clinical co-pilot designed to leverage AI in enhancing clinicians' diagnostic abilities.<br /> 

                        This version allows medical professionals <br /> to generate differential
                        diagnoses (ddx), <br /> suggest history gathering questions, physical exams, and
                        investigations, <br /> with the goal of reducing misdiagnosis.
                    </p>
                    <strong>Why:</strong>
                    <ul>
                        <li>Diagnostic errors account for 27.8% of  malpractice claims[1]</li>
                        <li>Efficient ddx can reduce diagnostic errors.[2]</li>
                        <li>Large Language Models have shown promise in providing expert-level ddx.[3]</li>
                    </ul>
                    <p>We aim to provide an intuitive interface for clinicians to access these AI tools.</p>
                    <hr />
                    <p>
                        Before proceeding, please agree the terms of usage:
                    </p>
                    <ul>
                        <li>Use is only for <strong>licensed medical professionals</strong></li>
                        <li>AI suggestions are references intended to support, not replace, clinical judgement.</li>
                        <li>This is a reference suggstion tool, the tool gives suggestion, doesnt make diagnosis</li>
                        <li><strong>Do not input any Personal Identifiable Information (PII).</strong></li>
                        <li>Be mindful of GPT-4 suggestions which <strong>may contain errors or biases.</strong></li>
                        <li>The developers of Osler would not be responsible for any harm caused by the use of this product.</li>
                    </ul>
                    <p>
                        By clicking 'Agree', you acknowledge understanding of the Osler tool and
                        its limitations.
                    </p>
                    <div className="consent-modal-footer">
                        <button className="consent-button" onClick={onConsent}>Agree</button>
                    </div>
                    <div className="consent-modal-citations" style={{ fontSize: 'small', color: 'grey' }}>
                        <p>
                            [1]Saber Tehrani AS, Lee H, Mathews SC, et al. 25-Year summary of US malpractice claims for diagnostic errors 1986-2010: an analysis from the National Practitioner Data Bank. BMJ Qual Saf. 2013;22(8):672-680. doi:10.1136/bmjqs-2012-001550<br />
                            [2] Maude, J. (2014). Differential diagnosis: the key to reducing diagnosis error, measuring diagnosis and a mechanism to reduce healthcare costs. Diagnosis, 1(1), 107-109. https://doi.org/10.1515/dx-2013-0009<br />
                            [3] McDuff, D., Schaekermann, M., Tu, T., Palepu, A., Wang, A., Garrison, J., ... & Natarajan, V. (2023). Towards Accurate Differential Diagnosis with Large Language Models. arXiv preprint arXiv:2312.00164.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ConsentModal;