import React, { useState } from "react";
import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import {
  BlockNoteView,
  getDefaultReactSlashMenuItems,
  ReactSlashMenuItem,
  useBlockNote,
  useEditorSelectionChange,
  FormattingToolbarPositioner,
  Toolbar,
  ToolbarButton,
  SlashMenuPositioner
} from "@blocknote/react";
import "@blocknote/react/style.css";
import { fetchDdx, fetchTidyNotes, fetchIllnessScript, fetchInvestigationSuggestion, fetchPESuggestion, fetchHistorySuggestions, fetchReview, fetchRedFlags, fetchReferralSuggestions, fetchFUSuggestions, fetchTxSuggestions, fetchRxSuggestions } from './apiFunctions.js';
import ConsentModal from './ConsentModal';
import { HiOutlineGlobeAlt, HiOutlineAdjustments, HiOutlineSearchCircle, HiOutlineLightBulb } from "react-icons/hi";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent} from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBmHi_lWXFv8eoLSFV7I408ILGTJpB1_i8",
  authDomain: "osler-7570a.firebaseapp.com",
  projectId: "osler-7570a",
  storageBucket: "osler-7570a.appspot.com",
  messagingSenderId: "177848826119",
  appId: "1:177848826119:web:8ed287d4f75daa1efd6da2",
  measurementId: "G-ZPLX0G1Y11"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const generateDdx = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => {
          if ('text' in contentItem) {
            return contentItem.text;
          }
          return '';
        }).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const ddxResult = await fetchDdx(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', ddxResult);

    // Ensure ddxResult is not null or undefined
    if (ddxResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: ddxResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const ddxBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([ddxBlock], currentBlock, "before");
      console.log('DDX result inserted into the editor.');

      // Log the function invocation using Firebase Analytics
      const analytics = getAnalytics();
      logEvent(analytics, 'generateDdx_triggered');
    } else {
      console.warn('DDX result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
};

//make InvestigationSuggestion like generateDDx
const generateInvestigationSuggestion = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => {
          if ('text' in contentItem) {
            return contentItem.text;
          }
          return '';
        }).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const investigationSuggestionResult = await fetchInvestigationSuggestion(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', investigationSuggestionResult);

    // Ensure investigationSuggestionResult is not null or undefined
    if (investigationSuggestionResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: investigationSuggestionResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const investigationSuggestionBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([investigationSuggestionBlock], currentBlock, "before");
      console.log('Investigation Suggestion result inserted into the editor.');
    } else {
      console.warn('Investigation Suggestion result is empty.');
    }
    const analytics = getAnalytics();
    logEvent(analytics, 'generateInvestigationSuggestion_triggered');
    } catch (error) {
      // Log any API error
      console.error('API error:', error);
      console.error(error.stack);
    }
  }


//make PE suggestion like generateDdx
const generatePESuggestion = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => contentItem.text).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const peSuggestionResult = await fetchPESuggestion(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', peSuggestionResult);

    // Ensure ddxResult is not null or undefined
    if (peSuggestionResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: peSuggestionResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const peSuggestionBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([peSuggestionBlock], currentBlock, "before");
      console.log('PE Suggestion result inserted into the editor.');

      // Log event to Firebase
      logEvent(analytics, 'pe_suggestion_generated');
    } else {
      console.warn('PE Suggestion result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
}

const generateHistorySuggestion = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => contentItem.text).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const historySuggestionResult = await fetchHistorySuggestions(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', historySuggestionResult);

    // Ensure ddxResult is not null or undefined
    if (historySuggestionResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: historySuggestionResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const historySuggestionBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([historySuggestionBlock], currentBlock, "before");
      console.log('History Suggestion result inserted into the editor.');

      // Log event to Firebase analytics
      logEvent(analytics, 'history_suggestion_generated');
    } else {
      console.warn('History Suggestion result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
}

const generateRedFlags = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => contentItem.text).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const redFlagsResult = await fetchRedFlags(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', redFlagsResult);

    // Ensure ddxResult is not null or undefined
    if (redFlagsResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: redFlagsResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const redFlagsBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([redFlagsBlock], currentBlock, "before");
      console.log('Red Flags result inserted into the editor.');

      // Log event to Firebase
      logEvent(analytics, 'red_flags_generated');
    } else {
      console.warn('Red Flags result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
}

const generateReferralSuggestions = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => contentItem.text).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const referralSuggestionResult = await fetchReferralSuggestions(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', referralSuggestionResult);

    // Ensure ddxResult is not null or undefined
    if (referralSuggestionResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: referralSuggestionResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const referralSuggestionBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([referralSuggestionBlock], currentBlock, "before");
      console.log('Referral Suggestion result inserted into the editor.');

      // Log event to Firebase
      logEvent(analytics, 'referral_suggestion_generated');
    } else {
      console.warn('Referral Suggestion result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
}

const generateFUSuggestions = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => contentItem.text).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const fuSuggestionResult = await fetchFUSuggestions(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', fuSuggestionResult);

    // Ensure ddxResult is not null or undefined
    if (fuSuggestionResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: fuSuggestionResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const fuSuggestionBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([fuSuggestionBlock], currentBlock, "before");
      console.log('FU Suggestion result inserted into the editor.');

      // Log event to Firebase
      logEvent(analytics, 'fu_suggestion_generated');
    } else {
      console.warn('FU Suggestion result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
}

const generateRxSuggestions = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }

    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => contentItem.text).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const rxSuggestionResult = await fetchRxSuggestions(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', rxSuggestionResult);

    // Ensure ddxResult is not null or undefined
    if (rxSuggestionResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: rxSuggestionResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const rxSuggestionBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([rxSuggestionBlock], currentBlock, "before");
      console.log('Rx Suggestion result inserted into the editor.');

      // Log event to Firebase
      logEvent(analytics, 'rx_suggestion_generated');
    } else {
      console.warn('Rx Suggestion result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
}

const generateTxSuggestions = async (editor) => {
  try {
    const topLevelBlocks = editor.topLevelBlocks;
    if (!topLevelBlocks || topLevelBlocks.length === 0) {
      console.warn('No blocks found in the editor.');
      return; // Exit early if no blocks
    }
    // Construct text from all blocks.
    const textToAnalyze = topLevelBlocks.map(block => {
      if (block.content && Array.isArray(block.content)) {
        return block.content.map(contentItem => {
          if ('text' in contentItem) {
            return contentItem.text;
          }
          return '';
        }).join(' ');
      }
      return '';
    }).join('\n');

    // Log the retrieved text
    console.log('Retrieved text from editor:', textToAnalyze);

    // Sending API Request
    console.log('Sending API request...');
    const txSuggestionResult = await fetchTxSuggestions(textToAnalyze);

    // Log what's received from the API
    console.log('Received from API:', txSuggestionResult);

    // Ensure txSuggestionResult is not null or undefined
    if (txSuggestionResult) {
      // Prepare content using InlineContent type specification.
      const inlineContent = [
        {
          type: "text",
          text: txSuggestionResult,
          styles: {} // Use appropriate styles if needed
        }
      ];

      // Create new block with the inline content.
      const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
      const txSuggestionBlock = {
        type: "paragraph",
        content: inlineContent,
      };

      // Insert the new block after the last block
      editor.insertBlocks([txSuggestionBlock], currentBlock, "before");
      console.log('Tx Suggestion result inserted into the editor.');

      // Log event to Firebase
      logEvent(analytics, 'tx_suggestion_generated');
    } else {
      console.warn('Tx Suggestion result is empty.');
    }
  } catch (error) {
    // Log any API error
    console.error('API error:', error);
    console.error(error.stack);
  }
}


// Command to fetch and console log DDX based on the editor content.
const generateDdxItem = {
  name: "Suggest DDX",
  execute: generateDdx,
  group: "AI Tools",
  icon: <HiOutlineGlobeAlt size={18} />,
  hint: "Analyze text to generate differential diagnoses.",
};

// Command to fetch and console log Investigation Suggestion based on the editor content.
const generateInvestigationSuggestionItem = {
  name: "Suggest Investigations",
  execute: generateInvestigationSuggestion,
  group: "AI Tools",
  icon: <HiOutlineAdjustments size={18} />,
  hint: "Analyze text to generate investigation suggestions.",
};

// Command to fetch and console log PE Suggestion based on the editor content.
const generatePESuggestionItem = {
  name: "Suggest Physical Exam",
  execute: generatePESuggestion,
  group: "AI Tools",
  icon: <HiOutlineSearchCircle size={18} />,
  hint: "Analyze text to generate physical exam suggestions.",
};

const generateHistorySuggestionItem = {
  name: "Suggest History Questions",
  execute: generateHistorySuggestion,
  group: "AI Tools",
  icon: <HiOutlineLightBulb size={18} />,
  hint: "Analyze text to generate history gathering suggestions.",
};

const generateRedFlagsItem = {
  name: "Suggest Red Flags",
  execute: generateRedFlags,
  group: "AI Tools",
  icon: <HiOutlineLightBulb size={18} />,
  hint: "Analyze text to generate red flags.",
};

const generateReferralSuggestionsItem = {
  name: "Suggest Referrals",
  execute: generateReferralSuggestions,
  group: "AI Tools",
  icon: <HiOutlineLightBulb size={18} />,
  hint: "Analyze text to generate referral suggestions.",
};

const generateFUSuggestionsItem = {
  name: "Suggest Follow Up",
  execute: generateFUSuggestions,
  group: "AI Tools",
  icon: <HiOutlineLightBulb size={18} />,
  hint: "Analyze text to generate follow up suggestions.",
};

const generateRxSuggestionsItem = {
  name: "Suggest Rx",
  execute: generateRxSuggestions,
  group: "AI Tools",
  icon: <HiOutlineLightBulb size={18} />,
  hint: "Analyze text to generate Rx suggestions.",
};

const generateTxSuggestionsItem = {
  name: "Suggest Tx",
  execute: generateTxSuggestions,
  group: "AI Tools",
  icon: <HiOutlineLightBulb size={18} />,
  hint: "Analyze text to generate Tx plan suggestions.",
};

const aiToolSlashMenuItems = [
  generateDdxItem,
  generateHistorySuggestionItem,
  generatePESuggestionItem,
  generateInvestigationSuggestionItem,
  generateRedFlagsItem,
  generateReferralSuggestionsItem,
  generateFUSuggestionsItem,
  generateRxSuggestionsItem,
  generateTxSuggestionsItem,
  // Add more custom items here as needed
];



// const replaceSelectedTextWithTidy = async (editor) => {
//   try {
//     // Step 1: Retrieve current selection
//     const selection = editor.getSelection();

//     // Make sure some blocks are actually selected
//     if (!selection || selection.blocks.length === 0) {
//       console.warn('No blocks are selected.');
//       return;
//     }

//     // Step 2: Extract text from selected blocks
//     const selectedText = selection.blocks.map(block => {
//       return block.content.map(contentItem => contentItem.text).join(' ');
//     }).join('\n');

//     // Make sure that there's actual text selected
//     if (selectedText.trim().length === 0) {
//       console.warn('The selection does not contain any text.');
//       return;
//     }

//     // Step 3: Fetch tidy text using the selected text
//     console.log('Sending API request...');
//     const tidyText = await fetchTidyNotes(selectedText);

//     // Log what's received from the API
//     console.log('Received tidy text from API:', tidyText);

//     // Step 4: Replace the text in the first selected block with tidyText and clear the others
//     if (tidyText) {
//       const firstBlock = selection.blocks[0];
//       const newContent = [{ type: "text", text: tidyText, styles: {} }];
//       editor.updateBlock(firstBlock.id, { ...firstBlock, content: newContent });

//       // If there are subsequent blocks, clear their content
//       if (selection.blocks.length > 1) {
//         for (let i = 1; i < selection.blocks.length; i++) {
//           editor.updateBlock(selection.blocks[i].id, { ...selection.blocks[i], content: [] });
//         }
//       }

//       console.log('Selected text replaced with tidy notes.');
//     } else {
//       console.warn('Tidy text result is empty.');
//     }
//   } catch (error) {
//     // Log any API error
//     console.error('Error while applying tidy notes:', error);
//   }
// };

// const reviewDocumentation = async (editor) => {
//   try {
//     const topLevelBlocks = editor.topLevelBlocks;
//     if (!topLevelBlocks || topLevelBlocks.length === 0) {
//       console.warn('No blocks found in the editor.');
//       return; // Exit early if no blocks
//     }

//     // Construct text from all blocks.
//     const textToAnalyze = topLevelBlocks.map(block => {
//       if (block.content && Array.isArray(block.content)) {
//         return block.content.map(contentItem => contentItem.text).join(' ');
//       }
//       return '';
//     }).join('\n');

//     // Log the retrieved text.
//     console.log('Retrieved text from editor:', textToAnalyze);

//     // Send the extracted text to our API for analysis.
//     const reviewResult = await fetchReview(textToAnalyze);
//     console.log('Received review from API:', reviewResult);

//     // Ensure reviewResult is not null or undefined and stringify it
//     if (reviewResult) {
//       const reviewResultString = JSON.stringify(reviewResult, null, 2); // Pretty print the result

//       // Prepare content using InlineContent type specification.
//       const inlineContent = [
//         {
//           type: "text",
//           text: reviewResultString,
//           styles: {} // Use appropriate styles if needed
//         }
//       ];

//       // Create new block with the inline content.
//       const currentBlock = topLevelBlocks[topLevelBlocks.length - 1]; // Assuming we want to add after the last block
//       const reviewBlock = {
//         type: "paragraph",
//         content: inlineContent,
//       };

//       // Insert the new block after the last block
//       editor.insertBlocks([reviewBlock], currentBlock, "before");
//       console.log('Review result inserted into the editor.');
//     } else {
//       console.warn('Review result is empty.');
//     }
//   } catch (error) {
//     // Log any API error
//     console.error('Error processing documentation review:', error);
//   }
// };








// const reviewDocumentationItem = {
//   name: "Review Documentation",
//   execute: reviewDocumentation,
//   group: "AI Tools",
//   icon: <HiOutlineGlobeAlt size={18} />,
//   hint: "Review documenetation using SaferDx framework.",
// };

// const tidyNotes = async (editor) => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(["Diagnosis A", "Diagnosis B", "Diagnosis C"]); // Simulated response
//     }, 1000);
//   });
// };

// // Sample command to perform clinical analysis
// const analyze = async (editor) => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(["Diagnosis A", "Diagnosis B", "Diagnosis C"]); // Simulated response
//     }, 1000);
//   });
// };

// // Sample command to refine diagnostic hypotheses
// const refineHypothesis = async (editor) => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(["Diagnosis A", "Diagnosis B", "Diagnosis C"]); // Simulated response
//     }, 1000);
//   });
// };
// // Each command should have its own item:



// const analyzeItem = {
//   name: "Analyze",
//   execute: analyze,
//   group: "AI Tools",
//   icon: <HiOutlineSearchCircle size={18} />,
//   hint: "Perform a comprehensive clinical analysis.",
// };

// const refineHypothesisItem = {
//   name: "Refine Hypothesis",
//   execute: refineHypothesis,
//   group: "AI Tools",
//   icon: <HiOutlineLightBulb size={18} />,
//   hint: "Refine diagnostic hypotheses based on new information.",
// };

// List containing only AI Tool Slash Menu Items (our custom set)



// const CustomFormattingToolbar = ({ editor }) => {
//   // Tracks whether the text is blue


//   // Replace highlighted text with tidy notes when the button is clicked
//   const handleTidyTextClick = async () => {
//     await replaceSelectedTextWithTidy(editor);
//   };

//   return (
//     <Toolbar>
//       <ToolbarButton
//         mainTooltip={"Tidy Selected Text"}
//         onClick={handleTidyTextClick}>
//         Tidy
//       </ToolbarButton>
//     </Toolbar>
//   );
// };


export default function App() {
  const [consentGiven, setConsentGiven] = useState(false);
  const editor = useBlockNote({
    slashMenuItems: aiToolSlashMenuItems,
  });

  const handleConsent = () => {
    setConsentGiven(true);
  };

  return (
    <div className="app-container" style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      {!consentGiven && <ConsentModal onConsent={handleConsent} />}

      {consentGiven && (
        <>
          <h1 className="page-title" style={{ fontSize: "24px", fontWeight: "bold" }}>
            Osler V0.0.11
          </h1>
          <h2 style={{ fontSize: "18px", marginBottom: "10px" }}>Instructions</h2>
          <p className="description" style={{ fontSize: "14px" }}>
            Use this app as your regular documentation input area.<br />
            <strong>Trigger AI functions by inputting "/", then select a function with the mouse or keyboard.</strong><br />
            Wait 5-10 seconds for AI to generate suggestions.<br />
            Copy and paste your documentation back to the EHR when done.<br />
          </p>
          <div className="block-note-container" style={{ border: "1px solid #ccc", padding: "10px" }}>
            <BlockNoteView editor={editor} theme="light">
              <SlashMenuPositioner editor={editor} />
            </BlockNoteView>
          </div>
        </>
      )}
    </div>
  );
}